import { NextParsedUrlQuery } from 'next/dist/server/request-meta';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import { firstExperimentalCategories, secondExperimentalCategories } from 'shared/utils/constants';
import { titleize, titleizeString } from 'shared/utils/helpers';
import type {
  CategoryRootDataModel,
  CategoryRootPageProps
} from 'types/oneflare.com.au/categoryRoot';
import type { ParamsModel } from 'types/oneflare.com.au/categoryShared';
import type { EntryPointPageModel } from 'types/oneflare.com.au/entryPage';

type PageTitleMetaDesc = {
  pageTitle: string;
  metaDescription: string;
};

type CategoryRootSharedProps = {
  isEntryPage: boolean;
  categoryRootData: Pick<CategoryRootDataModel, 'category'>;
  entryPointPage: Pick<EntryPointPageModel, 'entryPoint' | 'breadcrumbs'>;
} & ParamsModel;

const getCategoryRootPageTitleMetaDescription = ({
  isEntryPage,
  categorySlug,
  entryPointPage,
  categoryRootData
}: CategoryRootSharedProps): PageTitleMetaDesc => {
  let plural = '';
  switch (isEntryPage) {
    case true:
      ({
        entryPoint: {
          plural
        }
      } = entryPointPage);
      break;
    default:
      ({
        category: {
          plural
        }
      } = categoryRootData);
      break;
  }
  const titlelizedCategoryPlural = titleizeString(plural);
  let pageTitle = `Looking For The Best ${titlelizedCategoryPlural} | Oneflare`;
  let metaDescription = `Find the Best Local ${titlelizedCategoryPlural} as rated by other customers. List your job and receive up to ${publicRuntimeConfig.SITE_DEFAULT_QUOTES} FREE quotes today. With over 100,000 registered businesses we help you find the best company to complete your job.`;
  // TODO: Remove this once experiment has been validated
  if (firstExperimentalCategories.has(categorySlug)) {
    pageTitle = `Find Best ${titlelizedCategoryPlural} Near Me (Free Quotes) - Oneflare`;
    metaDescription = `Find the best trusted ${titlelizedCategoryPlural} with top ratings and reviews near you. List your job and get up to ${publicRuntimeConfig.SITE_DEFAULT_QUOTES} FREE quotes within 24 hours from qualified businesses.`;
  } else if (secondExperimentalCategories.has(categorySlug)) {
    pageTitle = `Best ${titlelizedCategoryPlural} Near Me (Free Quotes)`;
    metaDescription = `Find the best affordable ${titlelizedCategoryPlural} with top ratings and reviews near you. List your job and get up to ${publicRuntimeConfig.SITE_DEFAULT_QUOTES} FREE quotes within 24 hours from qualified businesses.`;
  }
  // TODO: Remove this once experiment has been validated
  return {
    pageTitle,
    metaDescription
  };
};

const getCategoryRootBreadcrumbs = ({
  isEntryPage,
  categorySlug,
  entryPointPage,
  categoryRootData
}: CategoryRootSharedProps) => {
  if (isEntryPage) {
    const { breadcrumbs } = entryPointPage;
    return {
      navigation: breadcrumbs.map(({ title, url }) => ({
        title,
        link: url
      }))
    };
  }

  const {  category: { name } } = categoryRootData;
  return {
    navigation: [
      {
        title: 'Oneflare',
        link: '/'
      },
      {
        title: `${titleize(name)}`,
        link: `/${categorySlug}`
      }
    ]
  };
};

const getPrefetchHero = ({
  categoryDirectoryContent
}: CategoryRootDataModel) => {
  let heroImage = '';
  if (categoryDirectoryContent) {
    ({ heroImage } = categoryDirectoryContent);
  }
  return heroImage;
};

type RouterParams = {
  query: NextParsedUrlQuery,
  pathname: string;
}

type PageTracking = {
  environment: string;
  deploymentVersion: string;
  locationId: string;
  categoryId: string;
  categoryName: string;
  zoneId: string;
  region_id: string;
  state_id: string;
};

export const getCategoryRootPageContent = (
  {
    categoryRoot: categoryRootData,
    categoryRoot: {
      categoryDirectoryContent,
      categoryDirectoryContent: {
        heroImage
      },
      averageRating,
      directLeadsNumber,
      directLeadsNumberFormatted,
      showDspHero,
      dynamicPageData,
      category: defaultCategory
    },
    categoryChoiceTips,
    categorySlugForQuery,
    entryPageChoiceTips,
    entryPointPage,
    isEntryPage,
    mainCities,
    params: categoryParams,
    queryPerPage
  }: CategoryRootPageProps,
  { query }: RouterParams,
  pageTracking: PageTracking
) => {
  const params = {
    ...categoryParams,
    ...query
  };
  const { pageTitle, metaDescription } = getCategoryRootPageTitleMetaDescription({
    isEntryPage,
    entryPointPage,
    categoryRootData,
    categorySlug: params.categorySlug
  });

  const { navigation } = getCategoryRootBreadcrumbs({
    isEntryPage,
    entryPointPage,
    categoryRootData,
    categorySlug: params.categorySlug
  });

  const prefetchHero = getPrefetchHero({ categoryDirectoryContent });

  const headContentProps = {
    pageUrl: `${publicRuntimeConfig.SITE_URL}/${params.categorySlug}`,
    tracking: pageTracking,
    pageTitle,
    prefetchHero,
    metaDescription
  };

  const headerProps = {
    isBot: params.staticRender,
    page: 'CAT/ROOT'
  };

  const heroProps = {
    averageRating,
    showDspHero,
    directLeadsNumber,
    directLeadsNumberFormatted,
    dynamicPageData,
    isEntryPage,
    heroImage,
    params,
    category: defaultCategory,
    entryPagePlural: entryPointPage?.entryPoint?.plural,
    page: 'CAT/ROOT'
  };

  const mainCityProps = {
    mainCities: isEntryPage ? entryPointPage?.mainCityLinks : mainCities,
    categorySlug: params.categorySlug
  };

  const breadCrumbsProps = {
    navigation
  };

  const businessesPaginationProps = {
    queryPerPage,
    categorySlugForQuery,
    isSeoCatloc: false,
    location: null
  };

  const renderChoiceTipsProps = {
    categoryChoiceTips,
    entryPageChoiceTips
  };

  return {
    breadCrumbsProps,
    businessesPaginationProps,
    headContentProps,
    headerProps,
    heroProps,
    mainCityProps,
    params,
    renderChoiceTipsProps
  };
};
