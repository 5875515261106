import type { NextPage } from 'next';
import type { UserAgent } from 'next-useragent';
import { withUserAgent } from 'next-useragent';

import categoryRootStaticProps from '@oneflare-static-props/_categoryRootPage';
import ApolloRun from 'lib/utils/apolloRun';
import { GET_CATEGORIES } from 'queries/oneflare.com.au/getCategories';
import { ENTRY_PAGE_CATEGORIES } from 'shared/utils/constants';
import { useBotDetection } from 'shared/utils/hooks';
import Category from 'src/pages/oneflare.com.au/Category';
import type {
  CategoryRootPageProps
} from 'types/oneflare.com.au/categoryRoot';

type CategoryRootPagePropsWithUserAgent = {
  ua: UserAgent;
} & CategoryRootPageProps;

const CategoryRootPage: NextPage<CategoryRootPagePropsWithUserAgent> = ({
  ua,
  ...rest
}) => {
  // #region bot detection
  // as request headers aren't available on ssg pages
  // bot detection has been moved to client-side
  const { isBot, staticRender } = useBotDetection(ua);
  // #endregion bot detection

  // assign new params
  const params = { staticRender, isBot };

  // assign new props
  const newProps = {
    ...rest,
    params: { ...rest.params, ...params }
  };
  return (
    <Category {...newProps} />
  );
};

export async function getStaticPaths() {
  const runApollo = new ApolloRun();
  const [data, apolloError] = await runApollo.query<{
    categories: {
      slug: string;
      id: string;
      name: string;
    }[];
  }>(GET_CATEGORIES, {
    domain: 'oneflare'
  });

  if (!data || apolloError) {
    return {
      paths: [],
      fallback: 'blocking'
    };
  }

  const { categories } = data;

  const defaultPaths = categories.slice(0, 30).map(({ slug: categorySlug }) => ({
    params: { categorySlug }
  }));

  const entryPagePaths = ENTRY_PAGE_CATEGORIES.map((categorySlug) => ({
    params: { categorySlug }
  }));

  const paths = [...defaultPaths, ...entryPagePaths];

  return {
    paths,
    fallback: 'blocking'
  };
}

const categoryRootPage = withUserAgent(CategoryRootPage);
export { categoryRootPage as default,  categoryRootStaticProps as getStaticProps };
